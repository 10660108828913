// ล่าสุด
import React, { useEffect, useContext, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Form, notification } from 'antd'
import UserContext from 'core/contexts/userContext'
import { getMe, postData } from 'core/action/collection'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'
import { ReactComponent as LogoKorat } from 'assets/images/svg/logo-koratcity.svg'
import { ReactComponent as LogoLine } from 'assets/images/svg/logo-line.svg'
import * as Styled from './Login.style'
import axios from 'axios'
import liff from '@line/liff'

function LoginPage() {
  const navigate = useNavigate()
  const { setMeData } = useContext(UserContext)
  const [api, contextHolder] = notification.useNotification()
  const [notiStyle, setNotiStyle] = useState(false)
  const [userId, setUserId] = useState('')
  const [displayName, setDisplayName] = useState('')

  const [loading, setLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    liff
      .init({ liffId: '2002152252-eRGPMl8G' }) //SIT
      // .init({ liffId: '2003472563-j4RMeNeN' }) //PROD
      .then(() => {
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then(profile => {
              setUserId(profile.userId)
              setDisplayName(profile.displayName)
              login(profile.userId, profile.displayName)
            })
            .catch(err => console.error(err))
        } else {
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
      })
  }, [])

  const openNotification = placement => {
    api.info({
      message: 'ออกจากระบบสำเร็จ',
      placement,
      icon: <IconSurprised />,
      closeIcon: <IconClose />,
      duration: 3,
    })
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const line_id_data = searchParams.get('line_id')
    if (line_id_data) {
      login(line_id_data)
    }
  }, [])
  const login = async (id, displayName) => {
    const res = await postData(`${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/login-line`, {
      line_id: id,
    })

    if (res) {
      if (res.code === 401) {
        navigate('/flow-login/email', { state: { line_id: id, line_name: displayName } })
      } else if (res.code === 200) {
        localStorage.setItem('access_token', res.data.access_token)
        localStorage.setItem('refresh_token', res.data.refresh_token)
        localStorage.setItem('user', true)
        const getDataMe = async () => {
          const resGetMe = await getMe({ token: res.data.access_token })
          if (resGetMe) {
            localStorage.setItem('organize', resGetMe.data.organize_data.org_id)
            setMeData(resGetMe?.data)
            let checkpath = resGetMe?.data?.menus?.filter((value, index, array) => value?.key_slug === 'complaint')
            if (checkpath) {
              if (resGetMe?.data?.position_data?.id === '1730459515121299456') {
                navigate('/complaint/bof-overview-complaint')
              } else {
                navigate('/complaint-staff/bof-overview-complaint')
              }
            } else {
              navigate(`/${resGetMe?.data?.menus[0]?.key_slug}/${resGetMe?.data?.menus[0]?.sub_menus[0]?.key_slug}`)
            }
          }
        }
        getDataMe()
      } else {
      }
    } else {
    }
  }

  const callfunc = async () => {
    await getProfileLine()
  }

  const getProfileLine = async () => {
    await liff
      .init({ liffId: '2002152252-eRGPMl8G' }) //SIT
      // .init({ liffId: '2003472563-j4RMeNeN' }) //PROD
      .then(() => {
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then(profile => {
              setDisplayName(profile.displayName)
              setUserId(profile.userId)
              login(profile.userId)
            })
            .catch(err => console.error(err))
        } else {
          liff.login()
        }
      })
      .catch(err => {
        console.log('err', err)
        localStorage.clear()
        navigate('/login')
      })
  }

  return (
    <>
      {notiStyle && <Styled.NotiStyle />}
      {contextHolder}
      {!loading ? (
        <Styled.Container>
          <>
            <LogoKorat />
            <Styled.BtLoginLine onClick={() => callfunc()}>
              <LogoLine />
              เข้าสู่ระบบ/ลงทะเบียนผ่านไลน์
            </Styled.BtLoginLine>
          </>
        </Styled.Container>
      ) : (
        ''
      )}
    </>
  )
}

export default LoginPage
